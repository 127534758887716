import { createAsyncThunk } from '@reduxjs/toolkit'
import { ORDER_CONFIGS } from '../../../configs/order'
import { ORDER_ITEM_SUBSCRIPTION_ATTRIBUTE, SUCCESS_MSG_PREFIX } from '../../../constants/common'
import { CART } from '../../../constants/routes'
import cartService from '../../../foundation/apis/transaction/cart.service'
import { IProduct } from '@typesApp/product'
import { sendSuccessMessage } from '../../success/slice'
import { toggleAddContactLensesToCartError, toggleAddSubscriptionToCartError } from '../../product/slice'
import { ORDER_EXTEND_ATTRIBUTE_NAMES } from '../../../constants/order'
import { ContactLensPayload, PrescriptionFormData } from '@typesApp/prescription'
import { AxiosError } from 'axios'
import { SubscriptionInfo } from '@typesApp/subscription'
import { SUBSCRIPTION_ERROR_KEYS } from '@views/Subscription/constants'
import { orderApi } from '../query'
import { prescriptionDetailsSelector, productContactLensesDataSelector } from '@features/product/selector'
import { RootReducerState } from '@redux/rootReducer'
import { siteSelector } from '@redux/selectors/site'
import config from '@configs/index'
import { AppDispatch } from '@redux/store'
import { clearPrescriptionCache, prescriptionCacheSelector } from '@features/cartui/cartuiSlice'

type OrderItem = {
  orderItemId: string
}

export type CartOrder = {
  orderId: string
  orderItem: OrderItem[]
  resourceName: string
  prescription?: PrescriptionFormData
}

export type ContactLensesAddItemArgs = {
  contractId?: string
  callback?: (order?: CartOrder) => void
  product?: IProduct
  updateCart?: boolean
  langId: string
  subscriptionInfo?: SubscriptionInfo
  discount?: { amount: string; userSegment: string }
  items:
    | {
        x_contactLens: ContactLensPayload
        quantity: string
      }
    | {}[]
    | null
}

const addContactLenses = createAsyncThunk<
  any,
  ContactLensesAddItemArgs,
  {
    state: RootReducerState
    dispatch: AppDispatch
  }
>('order/addContactLensItem', async (args, { dispatch, rejectWithValue, getState }) => {
  try {
    const payload = args
    const addToCartSuccessCallback = args.callback

    const _orderExtendAttributes = {
      attributeName: ORDER_EXTEND_ATTRIBUTE_NAMES.LANG_ID,
      attributeType: 'string',
      attributeValue: payload.langId,
    }

    const body = {
      body: {
        orderId: '.',
        x_calculateOrder: ORDER_CONFIGS.calculateOrder,
        orderItem: args.items,
        x_inventoryValidation: ORDER_CONFIGS.inventoryValidation,
        orderExtendAttribute: [_orderExtendAttributes],
        x_calculationUsage: ORDER_CONFIGS.calculationUsage,
      },
      skipErrorSnackbar: true,
    }

    const { subscriptionInfo } = args
    if (subscriptionInfo?.active) {
      body.body[ORDER_ITEM_SUBSCRIPTION_ATTRIBUTE] =
        `${subscriptionInfo.recurrency?.value}|${subscriptionInfo.recurrency?.interval}`
    }

    const response = await cartService.addOrderItem(body)
    dispatch(
      sendSuccessMessage({
        key: SUCCESS_MSG_PREFIX + 'ITEM_ADD_SUCCESS',
        link: {
          url: CART,
          textKey: SUCCESS_MSG_PREFIX + 'ViewCart',
        },
      })
    )

    const state = getState()

    await createCLPrescriptionDetails({
      state,
      orderId: response.data.orderId,
      orderItemIds: response.data.orderItem.map((item: any) => item.orderItemId),
      dispatch,
    })

    addToCartSuccessCallback?.(response?.data)

    return response.data
  } catch (error) {
    const axiosError = error as AxiosError<any>
    const subscriptionError = axiosError?.response?.data.errors?.find(err =>
      SUBSCRIPTION_ERROR_KEYS.includes(err.errorKey)
    )

    if (subscriptionError) {
      dispatch(toggleAddSubscriptionToCartError(subscriptionError.errorKey))
    } else {
      dispatch(toggleAddContactLensesToCartError(true))
    }

    return rejectWithValue(error)
  }
})

export default addContactLenses

const createCLPrescriptionDetails = async (params: {
  state: RootReducerState
  orderId: string
  orderItemIds: string[]
  dispatch: AppDispatch
}) => {
  const { state, orderId, orderItemIds, dispatch } = params
  const createPrescriptionMutation = orderApi.endpoints.createPrescriptionDetails.initiate
  const mySite = siteSelector(state)
  const { doctor, issue } = prescriptionDetailsSelector(state)
  const ContactLensesData = productContactLensesDataSelector(state)
  const rxTaxExemptionEnabled = config.rxTaxExemptionLocales.includes(mySite?.locale ?? '')
  const prescriptionCache = prescriptionCacheSelector(state)?.prescriptionDetails

  if (!rxTaxExemptionEnabled) {
    return
  }

  const canUseCahce = !!prescriptionCache
  const rxPayload = {
    orderId: orderId,
    orderItemId: orderItemIds.join(','),
    prescription: {
      issue: prescriptionCache && !issue ? new Date(`${prescriptionCache['issue']}`) : issue,
      doctor: prescriptionCache && !doctor ? prescriptionCache['doctor'] : doctor,
      productType: 'CL',
      nickName: '',
      firstName: '',
      lastName: '',
      telephone: '',
      dateOfBirth: '',
      prescriptionState: '',
      rightBaseCurve: `${(canUseCahce ? prescriptionCache['rBaseCurve'] : ContactLensesData?.right?.['x_baseCurve']) ?? ''}`,
      rightDiameter: `${(canUseCahce ? prescriptionCache['rDiameter'] : ContactLensesData?.right?.['x_diameter']) ?? ''}`,
      rightPrismPower: `${
        (canUseCahce ? prescriptionCache['rPrismPower'] : ContactLensesData?.right?.['x_spherePower']) ?? ''
      }`,

      leftBaseCurve: `${(canUseCahce ? prescriptionCache['lBaseCurve'] : ContactLensesData?.left?.['x_baseCurve']) ?? ''}`,
      leftPrismPower: `${(canUseCahce ? prescriptionCache['lPrismPower'] : ContactLensesData?.left?.['x_spherePower']) ?? ''}`,
      leftDiameter: `${(canUseCahce ? prescriptionCache['lDiameter'] : ContactLensesData?.left?.['x_diameter']) ?? ''}`,
    },
  }

  if (!rxPayload.prescription.issue) {
    return
  }

  dispatch(clearPrescriptionCache())

  await dispatch(createPrescriptionMutation(rxPayload)).unwrap()
}
