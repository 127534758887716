import { PartNumberAlgoliaPrice } from '@foundation/algolia/algoliaPrice'
import { CommonData } from '@foundation/analytics/tealium/interfaces'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { LensObject, PrescriptionFlow, PrescriptionObject, RXFileLink } from '@typesApp/rxConfigurator'
import { isRxLens, isRxService } from '@utils/rx'
import { ImageryType } from '../../../components/PrescriptionLenses/PrescriptionLenses'
import { ORDER_CONFIGS } from '../../../configs/order'
import { HTTP_CODE_OK, PRESCRIPTION_FLOW_MAP, SUCCESS_MSG_PREFIX } from '../../../constants/common'
import { CART } from '../../../constants/routes'
import { sendAddToCartEvent } from '../../../foundation/analytics/tealium/lib'
import cartService from '../../../foundation/apis/transaction/cart.service'
import { localStorageUtil } from '../../../foundation/utils/storageUtil'
import { sendSuccessMessage } from '../../success/slice'
import fetchCart from './fetchCart'
import { RootReducerState, SiteInfo } from '@redux/rootReducer'
import { Attribute, OrderItem } from '@typesApp/order'
import { doneEditingCartItem } from '../../cartui/cartuiSlice'
import rxService from '../../../foundation/apis/rx-config/rx.service'
import config from '@configs/index'
import {
  getRxValuesFromRxLens,
  LENSES_REVIEW_ATTRIBUTE_NAME,
  LensesReviewRoxObjectInterface,
  MDT_DISCOUNT_AMOUNT,
  MDT_DISCOUNT_SEGMENT,
} from '@components/PrescriptionLenses/RxUtils'
import { isEmpty } from 'lodash-es'
import { roxApi } from '@features/rox/roxApi'
import {
  LENSES_SERVICE_TYPE,
  RX_ADD_DEFAULT_VALUE,
  RX_AXIS_DEFAULT_VALUE,
  RX_CYLINDER_DEFAULT_VALUE,
  RX_PD_DEFAULT_VALUE,
  RX_SPHERE_DEFAULT_VALUE,
} from '@components/PrescriptionLenses/constants'
import { RX_PRESCRIPTION_OBJECT_KEY } from '@constants/rxConfigurator'

export interface IAddLensArgs {
  params: {
    partnumber: string | string[]
    isUndoRemoval?: boolean
    quantity: string | string[]
    catentryId?: string | string[]
    contractId?: string
    widget?: string
    langId: string
    product?: any
    fromRxPanel?: boolean
    userInfo?: any
    images?: ImageryType
    lensObject?: LensObject
    frameDiscount?: { amount: string; customerSegment: string }
    lensDiscount?: { amount: string; customerSegment: string }
    lensesReviewObject?: LensesReviewRoxObjectInterface
  }
  callback?: any
  siteInfo: SiteInfo
  isEditingLenses?: boolean
  productInCart?: OrderItem
  lastRemovedProduct?: OrderItem
  analyticsDataForCart?: Partial<CommonData>
  algoliaPrices?: PartNumberAlgoliaPrice
}
interface IAddLensRXC3Args {
  partnumber: string | string[]
  lensObject?: LensObject
  widget?: string
  savedPrescription?: any
  isEditingLenses?: boolean
  productInCart?: OrderItem
  lastRemovedProduct?: OrderItem
  lensesReviewObject?: LensesReviewRoxObjectInterface
  dispatch: any
  issue?: string
  doctor?: string
}

export interface ICommonArgs {
  partnumbers: string[]
  quantities: any[]
  langId: string
  widget?: string
  contractId?: string
  lensesReviewObject?: LensesReviewRoxObjectInterface
}

export interface IAddSingleLensArgs extends ICommonArgs {
  catentryIds: string[]
  images?: ImageryType
  lensesReviewObject?: LensesReviewRoxObjectInterface
}

export interface IAddFrameArgs extends ICommonArgs {
  orderItemId: string
  discount?: { amount: string; customerSegment: string }
}

const SAVE_PRESCRIPTION_FLOWS: PrescriptionFlow[] = ['MANUAL', 'ACCOUNT']

const retrieveRoxLensOrderItemId = (orderItems: OrderItem[], currentOrderItemIds: string[]) => {
  const filteredCurrentOrderItems = orderItems.filter((item: OrderItem) =>
    currentOrderItemIds.includes(item.orderItemId)
  )
  const currentRoxLensOrderItem = filteredCurrentOrderItems.find((item: OrderItem) => {
    return Boolean(
      item.orderItemExtendAttribute.find((attribute: Attribute<string>) => {
        return attribute.attributeName === 'IsRoxLens' && attribute.attributeValue === 'true'
      })
    )
  })
  return currentRoxLensOrderItem?.orderItemId
}

const addFrame = createAsyncThunk<any, IAddFrameArgs>('order/addFrame', async args => {
  const { orderItemId, partnumbers, quantities, langId, widget, contractId, discount } = args
  const _orderFrameExtendAttribute: any[] = []
  const _orderFrameExtendAttributes: any[] = []
  const _orderFrameItems: any[] = []

  for (const i in partnumbers) {
    if (orderItemId) {
      _orderFrameExtendAttribute.push(
        ...[
          {
            attributeName: 'IsRox',
            attributeType: 'String',
            attributeValue: 'true',
          },
          {
            attributeName: 'RxLensId',
            attributeType: 'String',
            attributeValue: orderItemId,
          },
          {
            attributeName: MDT_DISCOUNT_AMOUNT,
            attributeType: 'String',
            attributeValue: discount?.amount ?? '',
          },
          {
            attributeName: MDT_DISCOUNT_SEGMENT,
            attributeType: 'String',
            attributeValue: discount?.customerSegment ?? '',
          },
        ]
      )
    }
    _orderFrameItems[i] = {
      quantity: quantities[i].toString(),
      partNumber: partnumbers[i],
      contractId,
      orderItemExtendAttribute: orderItemId ? _orderFrameExtendAttribute : undefined,
    }
    _orderFrameExtendAttributes[i] = {
      attributeName: 'LanguageId',
      attributeType: 'string',
      attributeValue: langId,
    }
  }

  const bodyFrame = {
    body: {
      orderId: '.',
      x_calculateOrder: ORDER_CONFIGS.calculateOrder,
      orderItem: _orderFrameItems,
      x_inventoryValidation: ORDER_CONFIGS.inventoryValidation,
      orderExtendAttribute: _orderFrameExtendAttributes,
      x_calculationUsage: ORDER_CONFIGS.calculationUsage,
    },
    widget,
  }

  return (await cartService.addOrderItem(bodyFrame)).data
})

export interface ILensItemArgs extends ICommonArgs {
  frameDiscount?: { amount: string; customerSegment: string }
  lensDiscount?: { amount: string; customerSegment: string }
}

export interface IAddRoxableArgs extends ILensItemArgs {
  catentryIds: string[]
}

const addRoxable = createAsyncThunk<any, IAddRoxableArgs>('order/addRoxable', async (args, { rejectWithValue }) => {
  try {
    const {
      quantities,
      catentryIds,
      langId,
      widget,
      contractId,
      lensesReviewObject,
      partnumbers,
      frameDiscount,
      lensDiscount,
    } = args
    const _orderItem: any[] = []

    catentryIds.forEach((catentryId, index) => {
      _orderItem.push({
        quantity: quantities[index].toString(),
        productId: catentryId,
        contractId: contractId,
        orderItemExtendAttribute: [
          {
            attributeName: 'IsRox',
            attributeType: 'String',
            attributeValue: 'true',
          },
          {
            attributeName: 'IsRoxLens',
            attributeType: 'String',
            attributeValue: 'true',
          },
          {
            attributeName: LENSES_REVIEW_ATTRIBUTE_NAME,
            attributeType: 'String',
            attributeValue: !isEmpty(lensesReviewObject) ? JSON.stringify(lensesReviewObject) : '',
          },
          {
            attributeName: MDT_DISCOUNT_AMOUNT,
            attributeType: 'String',
            attributeValue: lensDiscount?.amount ?? '',
          },
          {
            attributeName: MDT_DISCOUNT_SEGMENT,
            attributeType: 'String',
            attributeValue: lensDiscount?.customerSegment ?? '',
          },
        ],
      })
    })

    partnumbers.forEach((partnumber, index) => {
      _orderItem.push({
        quantity: quantities[index].toString(),
        partNumber: partnumber,
        contractId,
        orderItemExtendAttribute: [
          {
            attributeName: 'IsRox',
            attributeType: 'String',
            attributeValue: 'true',
          },
          {
            attributeName: 'IsRoxFrame',
            attributeType: 'String',
            attributeValue: 'true',
          },
          {
            attributeName: LENSES_REVIEW_ATTRIBUTE_NAME,
            attributeType: 'String',
            attributeValue: !isEmpty(lensesReviewObject) ? JSON.stringify(lensesReviewObject) : '',
          },
          {
            attributeName: MDT_DISCOUNT_AMOUNT,
            attributeType: 'String',
            attributeValue: frameDiscount?.amount ?? '',
          },
          {
            attributeName: MDT_DISCOUNT_SEGMENT,
            attributeType: 'String',
            attributeValue: frameDiscount?.customerSegment ?? '',
          },
        ],
      })
    })

    let bodyLens = {
      body: {
        orderId: '.',
        x_calculateOrder: ORDER_CONFIGS.calculateOrder,
        orderItem: _orderItem,
        x_inventoryValidation: ORDER_CONFIGS.inventoryValidation,
        orderExtendAttribute: [
          {
            attributeName: 'LanguageId',
            attributeType: 'string',
            attributeValue: langId,
          },
        ],
      },
      widget,
    }

    return (await cartService.addOrderItem(bodyLens)).data
  } catch (e) {
    return rejectWithValue(e)
  }
})

const addRXCItem = createAsyncThunk<any, IAddLensRXC3Args>('order/addRXCItem', async args => {
  const {
    partnumber,
    lensObject,
    widget,
    savedPrescription,
    isEditingLenses,
    productInCart,
    lastRemovedProduct,
    lensesReviewObject,
    dispatch,
    issue,
    doctor,
  } = args

  const services = lensObject?.lensPackage?.services ?? []
  const lensServices = services.map(serv => {
    return {
      upc: serv.upc,
      type: LENSES_SERVICE_TYPE,
    }
  })
  const lastRemovedServices = lastRemovedProduct?.roxableServices?.filter(order =>
    isRxService(order.orderItemExtendAttribute)
  )
  const lastRemovedRoxServices = lastRemovedServices?.map(serv => ({
    upc: serv.partNumber,
    type: LENSES_SERVICE_TYPE,
  }))
  const lastRemovedLens = lastRemovedProduct?.roxableServices?.find(order => isRxLens(order?.orderItemExtendAttribute))
  const lastRemovedRoxLens = lastRemovedLens?.partNumber
  const finalRoxServices = lastRemovedRoxServices ?? lensServices
  const finalRoxLensUPC = lastRemovedRoxLens ?? lensObject?.lensPackage.upc

  // TDO: EDIT LENSES
  const itemId = isEditingLenses && productInCart ? productInCart.orderItemId : undefined
  const rxc3Payload = {
    body: {
      itemId,
      orderItem: [
        {
          upc: partnumber,
          type: 'frame',
          orderItemExtendAttribute: [
            {
              attributeName: LENSES_REVIEW_ATTRIBUTE_NAME,
              attributeType: 'String',
              attributeValue: !isEmpty(lensesReviewObject) ? JSON.stringify(lensesReviewObject) : '',
            },
          ],
        },
        {
          upc: finalRoxLensUPC,
          type: 'Rox_lens',
          prescription: [
            {
              pupillaryDistance: savedPrescription?.PD?.OD ? String(savedPrescription.PD.OD) : '',
            },
            {
              rightSphere: savedPrescription?.SPH?.OD ? String(savedPrescription.SPH.OD) : RX_SPHERE_DEFAULT_VALUE,
            },
            {
              rightAdd: savedPrescription?.ADD?.OD ? String(savedPrescription.ADD.OD) : RX_ADD_DEFAULT_VALUE,
            },
            {
              rightAxis: savedPrescription?.AX?.OD ? String(savedPrescription.AX.OD) : RX_AXIS_DEFAULT_VALUE,
            },
            {
              rightCyl: savedPrescription?.CYL?.OD ? String(savedPrescription.CYL.OD) : RX_CYLINDER_DEFAULT_VALUE,
            },
            {
              leftSphere: savedPrescription?.SPH?.OS ? String(savedPrescription.SPH.OS) : RX_SPHERE_DEFAULT_VALUE,
            },
            {
              leftAdd: savedPrescription?.ADD?.OS ? String(savedPrescription.ADD.OS) : RX_ADD_DEFAULT_VALUE,
            },
            {
              leftAxis: savedPrescription?.AX?.OS ? String(savedPrescription.AX.OS) : RX_AXIS_DEFAULT_VALUE,
            },
            {
              leftCyl: savedPrescription?.CYL?.OS ? String(savedPrescription.CYL.OS) : RX_CYLINDER_DEFAULT_VALUE,
            },
            {
              lPupDistance: savedPrescription?.PD?.OS ? String(savedPrescription.PD.OS) : RX_PD_DEFAULT_VALUE,
            },
            {
              rPupDistance: savedPrescription?.PD?.OD ? String(savedPrescription.PD.OD) : RX_PD_DEFAULT_VALUE,
            },
            issue && {
              issue: new Date(issue).toISOString(),
            },
            doctor && {
              doctor,
            },
          ].filter(Boolean),
        },
        ...finalRoxServices,
      ],
    },
    widget,
    RXC3_ENABLED: true,
  }

  return await dispatch(roxApi.endpoints.addRxConfigurationItem.initiate(rxc3Payload)).unwrap()
})
const addLens = createAsyncThunk<any, IAddSingleLensArgs>('order/addSingleLens', async (args, { rejectWithValue }) => {
  try {
    const { quantities, catentryIds, langId, widget, contractId, images, lensesReviewObject } = args

    const _orderLensExtendAttribute: any[] = []
    const _orderLensExtendAttributes: any[] = []
    const _orderLensItems: any[] = []

    const imagesArray = [
      {
        attributeName: 'rxProductImage',
        attributeType: 'String',
        attributeValue: images?.productImage || '',
      },
      {
        attributeName: 'rxFallbackImage',
        attributeType: 'String',
        attributeValue: images?.fallbackImage || '',
      },
    ]
    for (const i in catentryIds) {
      _orderLensExtendAttribute.push(
        ...[
          {
            attributeName: 'IsRox',
            attributeType: 'String',
            attributeValue: 'true',
          },
          {
            attributeName: 'IsRoxLens',
            attributeType: 'String',
            attributeValue: 'true',
          },
          {
            attributeName: LENSES_REVIEW_ATTRIBUTE_NAME,
            attributeType: 'String',
            attributeValue: !isEmpty(lensesReviewObject) ? JSON.stringify(lensesReviewObject) : '',
          },
          ...imagesArray,
        ]
      )
      _orderLensItems[i] = {
        quantity: quantities[i].toString(),
        productId: catentryIds[i],
        contractId: contractId,
        orderItemExtendAttribute: _orderLensExtendAttribute,
      }
      _orderLensExtendAttributes[i] = {
        attributeName: 'LanguageId',
        attributeType: 'string',
        attributeValue: langId,
      }
    }

    const bodyLens = {
      body: {
        orderId: '.',
        x_calculateOrder: ORDER_CONFIGS.calculateOrder,
        orderItem: _orderLensItems,
        x_inventoryValidation: ORDER_CONFIGS.inventoryValidation,
        orderExtendAttribute: _orderLensExtendAttributes,
        x_calculationUsage: ORDER_CONFIGS.calculationUsage,
      },
      widget,
    }

    return (await cartService.addOrderItem(bodyLens)).data
  } catch (e) {
    return rejectWithValue(e)
  }
})

const addLensItem = createAsyncThunk<any, IAddLensArgs, {
  state: RootReducerState
}>(
  'order/addLens',
  async (args, { dispatch, rejectWithValue, getState }) => {
    if (!!config.rxc.isDataLightEnabled && !args.params.isUndoRemoval) {
      try {
        const { partnumber, quantity, contractId, widget, product, lensObject, lensesReviewObject } = args.params
        const {
          callback: addToCartSuccessCallback,
          isEditingLenses,
          productInCart,
          siteInfo: mySite,
          algoliaPrices,
          analyticsDataForCart,
          lastRemovedProduct,
        } = args
        const savedPrescription = localStorageUtil.get(RX_PRESCRIPTION_OBJECT_KEY)
        const quantities: any[] = ([] = quantity instanceof Array ? quantity : [quantity])

        await dispatch(
          addRXCItem({
            partnumber,
            lensObject,
            widget,
            savedPrescription,
            isEditingLenses,
            productInCart,
            lastRemovedProduct,
            lensesReviewObject,
            issue: savedPrescription?.issueDate,
            doctor: savedPrescription?.doctorName,
            dispatch,
          })
        ).unwrap()

        sendAddToCartEvent(product ? [product] : [], Number(quantities?.[0]) || 1, algoliaPrices, analyticsDataForCart)

        dispatch(
          fetchCart({
            ...args,
            widget,
            contractId,
          })
        )

        dispatch(
          sendSuccessMessage({
            key: SUCCESS_MSG_PREFIX + 'ITEM_ADD_SUCCESS',
            link: {
              url: CART,
              textKey: SUCCESS_MSG_PREFIX + 'ViewCart',
            },
          })
        )

        if (addToCartSuccessCallback) {
          addToCartSuccessCallback()
        }

        return true
      } catch (error) {
        return rejectWithValue(error)
      }
    }

    //TODO This is used when we are "undoing" delete from the cart , so we need to investigate a better way and replace it
    else {
      const {
        partnumber,
        catentryId,
        quantity,
        langId,
        contractId,
        widget,
        fromRxPanel,
        lensesReviewObject,
        product,
        userInfo,
        frameDiscount,
        lensDiscount,
      } = args.params
      try {
        const addToCartSuccessCallback = args.callback
        const mySite = args.siteInfo
        const rxObject =
          product && product?.roxableServices?.[0] && !fromRxPanel
            ? getRxValuesFromRxLens(product?.roxableServices[0], { fallbackValue: '' })
            : null

        let catentryIds: string[] = []
        let partnumbers: string[] = []
        let quantities: any[] = []

        if (partnumber) {
          partnumbers = partnumber instanceof Array ? partnumber : [partnumber]
        }

        if (catentryId) {
          catentryIds = catentryId instanceof Array ? catentryId : [catentryId]
        }

        quantities = quantity instanceof Array ? quantity : [quantity]

        const common: ILensItemArgs = {
          langId,
          partnumbers,
          quantities,
          contractId,
          widget,
          lensesReviewObject,
          frameDiscount,
          lensDiscount,
        }

        return dispatch(addRoxable({ ...common, catentryIds }))
          .unwrap()
          .then(responseLens => {
            const currentOrderItemIds = responseLens.orderItem.map((item: { orderItemId: string }) => item.orderItemId)

            return dispatch(
              fetchCart({
                ...args,
                widget,
                contractId,
              })
            )
              .unwrap()
              .then(cartResponse => {
                // Cart reponse contains an array of orderItems, we need to find the orderItemId of the lens we just added
                // to be able to link the prescription to it
                const orderItems = cartResponse?.data.orderItem

                dispatch(
                  sendSuccessMessage({
                    key: SUCCESS_MSG_PREFIX + 'ITEM_ADD_SUCCESS',
                    link: {
                      url: CART,
                      textKey: SUCCESS_MSG_PREFIX + 'ViewCart',
                    },
                  })
                )

                const currentRoxLensOrderItemId = retrieveRoxLensOrderItemId(orderItems ?? [], currentOrderItemIds)
                const prescriptionFromLocalStorage: PrescriptionObject =
                  localStorageUtil.get(RX_PRESCRIPTION_OBJECT_KEY)
                const getUserNames = {
                  firstName: userInfo?.firstName ?? product?.firstName ?? '',
                  lastName: userInfo?.lastName ?? product?.lastName ?? '',
                }

                if (!fromRxPanel && !!rxObject) {
                  const rxTaxExemptionEnabled = config.rxTaxExemptionLocales.includes(mySite?.locale ?? '')
                  let rxPrescription = {}

                  if (rxTaxExemptionEnabled) {
                    rxPrescription = {
                      issue: rxObject?.issue ? new Date(rxObject?.issue).toISOString() : '',
                      doctor: rxObject?.doctor ?? '',
                      productType: 'RX'
                    }
                  }

                  const prescriptionObject = {
                    orderId: responseLens.orderId,
                    orderItemId: currentRoxLensOrderItemId,
                    prescription: {
                      productType: rxObject?.type ? String(rxObject?.type) : '',
                      nickName: rxObject?.nickName ? String(rxObject?.nickName) : '',
                      firstName: getUserNames?.firstName ?? '',
                      lastName: getUserNames?.lastName ?? '',
                      telephone: userInfo?.phone1 ? String(userInfo.phone1) : '',
                      dateOfBirth: userInfo?.dateOfBirth ? String(userInfo.dateOfBirth) : '',
                      prescriptionState: userInfo?.prescriptionState ? String(userInfo.prescriptionState) : '',
                      pupillaryDistance: rxObject?.pupillaryDistance ? String(rxObject?.pupillaryDistance) : '',
                      rightSphere: rxObject?.rightSphere ? String(rxObject?.rightSphere) : '0.00',
                      rightAdd: rxObject?.rightAdd ? String(rxObject?.rightAdd) : '',
                      rightAxis: rxObject?.rightAxis ? String(rxObject?.rightAxis) : '0',
                      rightCyl: rxObject?.rightCylinder ? String(rxObject?.rightCylinder) : '0.00',
                      leftSphere: rxObject?.leftSphere ? String(rxObject?.leftSphere) : '0.00',
                      leftAdd: rxObject?.leftAdd ? String(rxObject?.leftAdd) : '',
                      leftAxis: rxObject?.leftAxis ? String(rxObject?.leftAxis) : '0',
                      leftCyl: rxObject?.leftCylinder ? String(rxObject?.leftCylinder) : '0.00',
                      lPupDistance: rxObject?.lPupDistance ? String(rxObject?.lPupDistance) : '',
                      rPupDistance: rxObject?.rPupDistance ? String(rxObject?.rPupDistance) : '',
                      ...rxPrescription,
                    },
                  }
                  return rxService.addPrescriptionDetails(mySite, prescriptionObject).then(responsePrescription => {
                    if (responsePrescription.data?.successCode !== HTTP_CODE_OK) {
                      throw new Error(responsePrescription.data?.successMessage)
                    }

                    return executeCallBack(dispatch, addToCartSuccessCallback)
                  })
                } else if (prescriptionFromLocalStorage) {
                  if (
                    prescriptionFromLocalStorage?.prescriptionFlow &&
                    SAVE_PRESCRIPTION_FLOWS.includes(prescriptionFromLocalStorage?.prescriptionFlow)
                  ) {
                    const prescriptionObject = {
                      orderId: responseLens.orderId,
                      orderItemId: currentRoxLensOrderItemId,
                      prescription: {
                        productType: String(rxObject?.type ?? ''),
                        nickName: String(rxObject?.nickName ?? ''),
                        firstName: getUserNames.firstName,
                        lastName: getUserNames.lastName,
                        telephone: String(userInfo?.phone1 ?? ''),
                        dateOfBirth: String(userInfo?.dateOfBirth ?? ''),
                        prescriptionState: String(userInfo?.prescriptionState ?? ''),
                        pupillaryDistance: String(prescriptionFromLocalStorage?.PD?.OD ?? ''),
                        rightSphere: String(prescriptionFromLocalStorage?.SPH?.OD ?? '0.00'),
                        rightAdd: String(prescriptionFromLocalStorage?.ADD?.OD ?? ''),
                        rightAxis: String(prescriptionFromLocalStorage?.AX?.OD ?? '0'),
                        rightCyl: String(prescriptionFromLocalStorage?.CYL?.OD ?? '0.00'),
                        leftSphere: String(prescriptionFromLocalStorage?.SPH?.OS ?? '0.00'),
                        leftAdd: String(prescriptionFromLocalStorage?.ADD?.OS ?? ''),
                        leftAxis: String(prescriptionFromLocalStorage?.AX?.OS ?? '0'),
                        leftCyl: String(prescriptionFromLocalStorage?.CYL?.OS ?? '0.00'),
                        lPupDistance: String(prescriptionFromLocalStorage?.PD?.OS ?? ''),
                        rPupDistance: String(prescriptionFromLocalStorage?.PD?.OD ?? ''),
                      },
                    }

                    return rxService.addPrescriptionDetails(mySite, prescriptionObject).then(responsePrescription => {
                      if (responsePrescription.data?.successCode !== HTTP_CODE_OK) {
                        throw new Error(responsePrescription.data?.successMessage)
                      }
                      if (prescriptionFromLocalStorage?.prescriptionFlow === PRESCRIPTION_FLOW_MAP.UPLOAD) {
                        const rxFileLink: RXFileLink = {
                          orderId: responseLens.orderId,
                          orderItemId: currentRoxLensOrderItemId,
                          rxFileStorageId: prescriptionFromLocalStorage?.savedFileName ?? '',
                        }

                        return rxService.linkRXFileWithOrder(mySite, rxFileLink).then(responsePrescription => {
                          if (responsePrescription.data?.successCode !== HTTP_CODE_OK) {
                            throw new Error(responsePrescription.data?.successMessage)
                          }
                          return executeCallBack(dispatch, addToCartSuccessCallback)
                        })
                      } else {
                        return executeCallBack(dispatch, addToCartSuccessCallback)
                      }
                    })
                  }
                }
                return executeCallBack(dispatch, addToCartSuccessCallback)
              })
          })
      } catch (error) {
        // ensure we unblock cart on error in edit mode
        dispatch(doneEditingCartItem())
        return rejectWithValue(error)
      }
    }
  }
)
const executeCallBack = (dispatch, addToCartSuccessCallback) => {
  if (addToCartSuccessCallback) {
    addToCartSuccessCallback()
  }
  return true
}

export default addLensItem

export { addFrame, addLens, addRXCItem }
